import { createRouter, createWebHistory } from "vue-router";

const Home = () => import("../views/home");
const About = () => import("../views/about");
const Product = () => import("../views/product");
const Default = () => import("../views/product/default.vue");
const Bus = () => import("../views/product/bus.vue");
const Car = () => import("../views/product/car.vue");
const Hotel = () => import("../views/product/hotel.vue");
const Media = () => import("../views/product/media.vue");
const Safe = () => import("../views/product/safe.vue");
const Ticket = () => import("../views/product/ticket.vue");
const Join = () => import("../views/join");
const Contact = () => import("../views/contact");
export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/home",
      component: Home,
    },
    {
      path: "/product",
      component: Product,
      children: [
        {
          path: "",
          component: Default,
        },
        {
          path: "bus",
          component: Bus,
        },
        {
          path: "car",
          component: Car,
        },
        {
          path: "hotel",
          component: Hotel,
        },
        {
          path: "media",
          component: Media,
        },
        {
          path: "safe",
          component: Safe,
        },
        {
          path: "ticket",
          component: Ticket,
        },
      ],
    },
    {
      path: "/about",
      component: About,
    },
    {
      path: "/join",
      component: Join,
    },
    {
      path: "/contact",
      component: Contact,
    },
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home", // 404 页面，重定向到主页
    },
  ],
});
