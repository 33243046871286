<template>
    <footer>

        <div class="center">
            <div class="flex">
                <div class="name">
                    北京亿朵翔云传媒科技有限公司
                </div>
                <div class="nav">
                    <a href="/home">首页</a>
                    <a href="/about">公司介绍</a>
                    <a href="/product">产品服务中心</a>
                    <a href="/join">招贤纳士</a>
                    <a href="/contact">联系我们</a>
                </div>
                <!-- <div style="width: 100px;"></div> -->
            </div>
            <div class="footer_info">
                <div class="flex_row">
                    <div class="left">
                        邮箱：hangtianxiangyun01@163.com
                    </div>
                    <div class="right">
                        地址:北京市朝阳区霄云路36号国航大厦16层16J
                    </div>
                </div>
                <div class="row">
                    所有图片来源于网络，如涉及侵权请联系删除
                </div>
                <div class="row">
                    <a href="https://beian.miit.gov.cn/#/Integrated/index">备案号：京ICP备2021026080号-2</a>
                    <!-- <div>备案号：京ICP备2021026080号-2</div> -->
                </div>
            </div>
        </div>
    </footer>
</template>
<style scoped>
footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e1e1e;
    color: #999;

    .center {

        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        .flex {
            padding: 50px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #2b2b2b;

            .name {
                width: 30%;
                font-size: 24px;
                color: #999;
            }

            .nav {
                display: flex;
                align-items: center;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    color: #999;
                    padding: 0 20px;

                    &:hover {
                        color: #ffc001;
                    }
                }
            }
        }

        .footer_info {
            padding: 40px 0;
            color: #747474;
            font-size: 14px;

            .flex_row {
                width: 60%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 auto;
            }

            .row {
                width: 100%;
                display: inline-block;
                text-align: center;
                color: #747474;
                font-size: 14px;
                margin-top: 18px;

                a {
                    color: rgb(116, 116, 116);
                }
            }
        }
    }
}
</style>
