<template>
  <header>
    <div class="content">
      <a href="/home" class="logo">
        <img src="../../assets//images/logo.png" alt="">
      </a>
      <div class="nav">
        <div class="list">
          <div class="item">
            <RouterLink to="/home">
              首页
            </RouterLink>
          </div>
          <div class="item">
            <RouterLink to="/about">
              公司介绍
            </RouterLink>
          </div>
          <div class="item">
            <RouterLink to="/product">
              产品服务中心
            </RouterLink>
          </div>
          <div class="item">
            <RouterLink to="/join">
              招贤纳士
            </RouterLink>
          </div>
          <div class="item">
            <RouterLink to="/contact">
              联系我们
            </RouterLink>
          </div>
        </div>
      </div>
      <div class="cs" style="width: 100px;">
        <!-- <div class="icon">
          <FontAwesomeIcon :icon="faPhone" style="color: #74C0FC;" />
        </div>
        <div class="font">
          咨询电话：<div class="phone">13303888377</div>
        </div> -->
      </div>
    </div>
  </header>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

</script>
<style scoped lang="scss">
header {
  width: 100%;

  .content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    .logo {
      width: 170px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    .nav {
      .list {
        display: flex;
        align-items: center;

        .item {
          padding: 0 18px;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          a {
            color: #333;
            font-weight: 500;
            display: flex;
            align-items: center;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            font-size: 16px;
            padding: 0 10px;
            height: 100%;
            transition: all .2s ease-in-out;
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);

            &::before {
              content: "";
              position: absolute;
              z-index: -1;
              left: 51%;
              right: 51%;
              bottom: 0px;
              background: #02afff;
              height: 2px;
              transition-property: left, right;
              transition-duration: .3s;
              transition-timing-function: ease-out;
            }
          }

          &:hover {
            a {
              color: #e83820;

              &::before {
                left: 0 !important;
                right: 0 !important;
              }
            }
          }

          .router-link-active {
            &::before {
              left: 0 !important;
              right: 0 !important;
            }
          }
        }
      }
    }

    .cs {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 10px;
        font-size: 22px;
      }

      .font {
        display: flex;
        align-items: center;

        .phone {
          font-size: 18px;
        }
      }
    }
  }
}
</style>